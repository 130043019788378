const theme = {
  breakpoints: ["480px", "768px", "1024px", "1400px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    monospace: "Open Sans, sans-serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#333",
    background: "#eee",
    primary: "#20bed6",
    secondary: "#30c",
    muted: "#585858",
    isWhite: "#fff",
    text__static: "#333",
    modes: {
      dark: {
        text: "#fff",
        background: "#333",
        primary: "#20bed6",
        isWhite: "#444",
        muted: "#cacaca",
      },
    },
  },
  text: {
    fontFamily: "body",
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      mb: 2,
      mt: 1,
    },
    tags: {
      fontSize: 0,
      color: "muted",
      paddingRight: "1rem",
      textTransform: "uppercase",
    },
    light: {
      color: "muted",
    },
  },
  images: {
    avatar: {
      borderRadius: 9999999,
      width: 48,
      height: 48,
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      backgroundColor: "background",
      a: {
        color: "inherit",
        textDecoration: "none",
      },
    },
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    image: {
      objectFit: "cover",
      width: "100%",
    },
    body: {},
    h1: {
      variant: "text.heading",
      fontSize: 5,
    },
    h2: {
      variant: "text.heading",
      fontSize: 5,
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}

export default theme
